import React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

const Disclaimer = ({ children, ...props }) => {
  return <StyledDisclaimer {...props}>{children}</StyledDisclaimer>
}

Disclaimer.propTypes = {
  children: PropTypes.node.isRequired,
}

const StyledDisclaimer = styled.p`
  color: #5d5d5d;
  font-family: 'JD Sans', sans-serif;
  font-size: 0.7em;
  margin: auto;
  width: 100%;

  a {
    color: #377539;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }
`

export default Disclaimer
