/**
 * Removes style attribute from an HTML string
 * @param {string} val - HTML to remove style attributes from
 * @returns {string} Cleaned HTML string
 */
const stripStyles = val => {
  if (!val) {
    return val
  }

  return val.replace(/style=('[^']+')?(\\?"[^"]+\\?")?/g, '')
}

module.exports = stripStyles
