const months = require('../constants/months')

/**
 * Get a month's name by number (starting at index 0)
 * @param {number} monthNumber
 */
const getMonthName = monthNumber => {
  return months[monthNumber]
}

module.exports = getMonthName
