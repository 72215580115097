import React, { useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import H4 from 'components/new/typography/h4'
import { useEffectOnce } from 'hooks/use-effect-once'
import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { LoadingScreen } from 'components/loading-screen'
import { isBrowser } from '@hutson/utils'

const Map = ({ locations }) => {
  const map = useRef(null)
  const mapContainerEl = useRef(null)
  const mapMarkers = useRef(null)

  useEffectOnce(() => {
    if (isBrowser) {
      const newMap = new window.google.maps.Map(mapContainerEl.current, {
        zoom: 8,
        center: {
          lat: 36.7256143,
          lng: -88.6635187,
        },
      })

      const markers = locations.map(location => {
        return new window.google.maps.Marker({
          position: location.position,
          title: location.title ? location.title : null,
          map: newMap,
        })
      })

      if (markers.length > 1) {
        const bounds = new window.google.maps.LatLngBounds()

        for (let i = 0; i < markers.length; i++) {
          bounds.extend(markers[i].position)
        }

        newMap.fitBounds(bounds)
      } else {
        newMap.setCenter(markers[0].position)
      }

      mapMarkers.current = markers
      map.current = newMap
    }
  })

  return <div ref={mapContainerEl} id='map' />
}

const render = status => {
  if (status === Status.FAILURE) {
    return <div>Failed to render map..</div>
  }
  return <StyledLoadingScreen loading={true} message='Loading map...' />
}

const LocationsMap = ({
  locations,
  sidebarTitle = 'Locations',
  sidebarTitleElement = 'h2',
  ...props
}) => {
  return (
    <Container {...props}>
      <div className='container-inner'>
        <div className='map-container'>
          <div className='map-container-inner'>
            <Wrapper apiKey={process.env.GATSBY_GOOGLE_API_KEY} render={render}>
              <Map locations={locations} />
            </Wrapper>
          </div>
        </div>
        <div className='sidebar'>
          <H4 as={sidebarTitleElement} className='sidebar-title'>
            {sidebarTitle}
          </H4>
          <ul className='sidebar-locations-list'>
            {locations.map(location => (
              <li key={location.title}>
                <div className='location-details'>
                  <div className='location-title'>{location.title}</div>
                  <div className='location-address'>{location.address}</div>
                  <Link to={`/locations/${location.handle}/`}>Learn more</Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  )
}

const StyledLoadingScreen = styled(LoadingScreen)`
  background-color: ${props => props.theme.color.n30};
  height: 100%;
  width: 100%;
`

const Container = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.n30};
  border-top: 1px solid ${props => props.theme.color.n30};

  .container-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  #map {
    height: 100%;
    width: 100%;
  }

  .sidebar {
    background-color: ${props => props.theme.color.n10};
    display: flex;
    flex-direction: column;
  }

  .sidebar-title {
    background-color: #fff;
    border-bottom: 1px solid ${props => props.theme.color.n30};
    width: 100%;
    padding: 24px;
  }

  .sidebar-locations-list {
    background-color: #fff;
    margin: 0;
    padding: 0;
    overflow-y: auto;

    li {
      border-bottom: 1px solid ${props => props.theme.color.n30};
      line-height: 1.5;
      margin: 0;
      padding: 16px 24px;

      a {
        color: ${props => props.theme.color.g400};
        display: inline-block;
        margin-top: 4px;
      }
    }
  }

  .location-title {
    font-weight: 700;
  }

  @media (max-width: 899.5px) {
    .map-container {
      display: block;
      height: 0px;
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      width: auto;
    }

    .map-container-inner {
      height: 100%;
      position: absolute;
      width: 100%;
    }

    .sidebar {
      max-height: 350px;

      .sidebar-locations-list {
        flex-grow: 2;
      }
    }
  }

  @media (min-width: 900px) {
    display: block;
    height: 0px;
    overflow: hidden;
    padding-bottom: 43.75%;
    position: relative;
    width: auto;

    .container-inner {
      display: flex;
      flex-direction: row-reverse;
      position: absolute;
    }

    .map-container {
      height: 100%;
      width: 65%;
    }

    .map-container-inner {
      height: 100%;
    }

    .sidebar {
      height: 100%;
      position: relative;
      width: 35%;
    }
  }
`

export default LocationsMap
