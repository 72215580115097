import React, { useState } from 'react'
import styled from 'styled-components'
import { Email } from 'components/forms/elements/v2/email'
import { FieldError } from 'components/forms/elements/v2/field-error'
import { Submit } from 'components/forms/elements/v2/submit'
import H2 from 'components/new/typography/h2'
import P from 'components/new/typography/p'
import Content from 'components/content-full-width'
import { useForm } from 'react-hook-form'
import { useIsMounted } from 'hooks/use-is-mounted'

const SUBMIT_STATE = {
  success: 'success',
  loading: 'loading',
  error: 'error',
}

const JobListingAlerts = () => {
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting, isValid },
  } = useForm({ mode: 'onBlur' })

  const [submitState, setSubmitState] = useState(null)

  const isMounted = useIsMounted()

  const onSubmit = data => {
    setSubmitState(SUBMIT_STATE.loading)

    const body = JSON.stringify(data)

    fetch('/api/job-listing-alerts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body,
    })
      .then(res => {
        if (isMounted()) {
          setSubmitState(res.ok ? SUBMIT_STATE.success : SUBMIT_STATE.error)
        }
      })
      .catch(err => {
        if (isMounted()) {
          console.error('Error submitting form: ', err)
          setSubmitState(SUBMIT_STATE.error)
        }
      })
  }

  const onError = errors => {
    console.error(errors)

    if (isValid) {
      setSubmitState(SUBMIT_STATE.error)
    }
  }
  return (
    <GreenBackground>
      <StyledContent>
        {submitState !== SUBMIT_STATE.success && (
          <form
            name='Job Listing Alerts'
            onSubmit={handleSubmit(onSubmit, onError)}
            method='post'
            id='job-listing-alerts-form'
            data-netlify='true'
          >
            <Container>
              <H2 as={Header}>Get email alerts when new jobs are added</H2>
              <div>
                <FormElements>
                  <StyledEmail register={register} placeholder='Enter your email' required />
                  <div style={{ left: '-9999px', top: '-9999px' }} className='visually-hidden'>
                    <label htmlFor='pardot_extra_field' tabIndex='-1'>
                      Comments
                    </label>
                    <input
                      ref={register()}
                      type='text'
                      id='pardot_extra_field'
                      name='pardot_extra_field'
                      tabIndex='-1'
                    />{' '}
                  </div>
                  <Submit loading={isSubmitting || submitState === SUBMIT_STATE.loading} />
                </FormElements>
                {errors['Email'] ? <StyledFieldError type={errors['Email'].type} /> : null}
              </div>
            </Container>
          </form>
        )}
        {submitState === SUBMIT_STATE.success && (
          <>
            <H2 as={SuccessHeader}>Thanks for subscribing!</H2>
            <SuccessMesssage>
              You've been added to our email list. We'll let you know when new jobs are posted.
            </SuccessMesssage>
          </>
        )}
        {submitState === SUBMIT_STATE.error && (
          <ErrorMessage>
            There was an issue submitting your form. Please try again. If problems persist, please
            contact <a href='mailto:contactus@hutsoninc.com'>our support team</a>.
          </ErrorMessage>
        )}
      </StyledContent>
    </GreenBackground>
  )
}

const GreenBackground = styled.div`
  background-color: ${props => props.theme.color.g400};
`

const StyledContent = styled(Content)`
  @media ${props => props.theme.mediaQuery.largeUp} {
    padding: ${props => `${props.theme.size.l} ${props.theme.size.xxl}`};
  }
`

const Container = styled.div`
  @media (min-width: 800px) {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
`

const Header = styled.span`
  color: #fff;
  display: inline-block;
  flex-grow: 2;
  margin-bottom: 24px;

  @media (min-width: 800px) {
    margin-bottom: 0;
  }
`

const FormElements = styled.div`
  label {
    display: none;
  }

  .submit-loading-circle {
    border-color: rgba(255, 255, 255, 0.15);
    border-top-color: rgba(255, 255, 255, 0.75);
  }

  @media (min-width: 480px) {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
`

const StyledEmail = styled(Email)`
  margin-bottom: 16px;
  max-width: 250px;

  @media (min-width: 480px) {
    height: auto !important;
    margin-bottom: 0;
    margin-right: 24px;
    margin-top: 0 !important;
    max-width: none;
    min-width: 250px;
  }
`

const StyledFieldError = styled(FieldError)`
  color: rgba(255, 255, 255, 0.75);
`

const SuccessHeader = styled.span`
  color: #fff;
  display: block;
  margin-bottom: 8px;
`

const SuccessMesssage = styled(P)`
  color: #fff;
  margin-bottom: 0;
`

const ErrorMessage = styled(P)`
  color: #fff;
  margin-top: 16px;

  a {
    color: #fff;
    text-decoration: underline;
  }
`

export default JobListingAlerts
