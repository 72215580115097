import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import Button from 'components/button/button'
import { graphql, useStaticQuery, Link } from 'gatsby'
import HeroImage from 'components/hero-image'
import H2Old from 'components/typography/h2'

const PositionRemovedView = () => {
  const { heroImage } = useStaticQuery(graphql`
    {
      heroImage: file(relativePath: { eq: "careers/jobs-hero.jpg" }) {
        ...FullWidthImage
      }
    }
  `)
  return (
    <Layout>
      <Helmet>
        <title>Job Opening Removed | Hutson Inc</title>
      </Helmet>

      <HeroImage title='Job Opening Removed' image={heroImage} />

      <Content>
        <H2Old textAlign='center'>This position has been filled</H2Old>
        <RemovedMessage>
          We're constantly adding new job openings to our site. Check out the{' '}
          <Link to='/careers/jobs/'>jobs page</Link> to see our current positions.
        </RemovedMessage>
        <div style={{ textAlign: 'center' }}>
          <Button as={RemovedButtonLink} to='/careers/jobs/'>
            View all open positions
          </Button>
        </div>
      </Content>
    </Layout>
  )
}

const RemovedMessage = styled.p`
  margin: 0 auto 15px;
  max-width: 600px;
  text-align: center;

  @media (min-width: 800px) {
    margin: 0 auto 30px;
  }
`

const RemovedButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

export default PositionRemovedView
