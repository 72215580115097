const getMonthName = require('./get-month-name')
const isValidDate = require('./is-valid-date')

/**
 * Format a date as a string
 * @param {Date} d
 * @returns {string|boolean} Returns formatted date or false if invalid date was provided
 */
const formatDate = d => {
  if (!isValidDate) {
    return false
  }
  const monthNumber = d.getUTCMonth()
  const dateNumber = d.getUTCDate()
  const yearNumber = d.getUTCFullYear()
  return `${getMonthName(monthNumber)} ${dateNumber}, ${yearNumber}`
}

module.exports = formatDate
