import React from 'react'
import styled from 'styled-components'

const Spacer = () => {
  return <Space />
}

const Space = styled.div`
  display: none;

  @media (min-width: 900px) {
    display: block;
    height: 75px;
  }
`

export default Spacer
